<template lang="pug">
  .app-container.users-list
    .filter-container
      .filter-container__row
        el-date-picker(v-model="query.date" type="datetimerange" start-placeholder="От" end-placeholder="До" style="margin-right: 30px")
        .filter-container__row-title Пользователь
        el-input(v-model="query.userId" placeholder="Id" type="number" style="width: 100px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-input(v-model="query.userName" placeholder="Имя" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-input(v-model="query.userEmail" placeholder="E-mail" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
      .filter-container__row
        el-input(v-model="query.status" placeholder="Статус" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-input(v-model="query.action" placeholder="Действие" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-input(v-model="query.pageName" placeholder="Страница" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-input(v-model="query.message" placeholder="Сообщение" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
        el-button(class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter") Найти
      // el-select(v-model="query.role" placeholder="Роль" clearable style="width: 90px" class="filter-item" @change="handleFilter")
        el-option(v-for="item in roles" :key="item" :label="item | uppercaseFirst" :value="item")
      // router-link(to="/admin/users/create")
        el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus") Создать
      //- el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" @click="handleCreate") Создать
      //- el-button(v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload") Экспорт

    el-table(v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%")
      el-table-column(align="center" label="ID" width="80")
        template(slot-scope="scope")
          span {{ scope.row.id }}

      //- el-table-column(align="center" label="Тип" width="60")
        template(slot-scope="scope")
          span {{ scope.row.type }}

      el-table-column(align="center" label="Статус" width="100")
        template(slot-scope="scope")
          span {{ scope.row.status }}

      el-table-column(align="center" label="Действие" width="120")
        template(slot-scope="scope")
          span {{ scope.row.action }}

      el-table-column(align="center" label="Страница" width="120")
        template(slot-scope="scope")
          span {{ scope.row.page }}

      el-table-column(align="center" label="IP" width="110")
        template(slot-scope="scope")
          span {{ scope.row.ip }}

      el-table-column(align="center" label="ID пользователя" width="70")
        template(slot-scope="scope")
          span {{ scope.row.user_id }}

      el-table-column(align="center" label="Имя пользователя" width="120")
        template(slot-scope="scope")
          span {{ scope.row.user_name }}

      el-table-column(align="center" label="Сообщение" width="200")
        template(slot-scope="scope")
          span {{ scope.row.message }}

      el-table-column(align="center" label="Дата и время" width="120")
        template(slot-scope="scope")
          span(style="word-break: normal") {{ scope.row.created_at }}

      el-table-column(align="center" label="Действия" width="150")
        template(slot-scope="scope")
          .users-list__action(@click.prevent="openViewModal(scope.row.id)")
            // v-if="!scope.row.roles.includes('admin')" v-permission="['manage user']"
            el-button(type="primary" size="small" icon="el-icon-view") Подробнее

    pagination(v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList")

    el-dialog.logs-list__view-modal(:visible.sync="dialogViewVisible" :title="'Просмотр лога - ' + current.id" width="65%" top="5vh")
      .form-container(v-loading="dialogViewLoading" v-if="current.type")
        .permissions-container
          el-form(label-width="170px").block
            el-form-item(label="ID")
              span {{ current.id }}
            el-form-item(label="Тип")
              span {{ current.type }}
            el-form-item(label="Статус")
              span {{ current.status }}
            el-form-item(label="Действие")
              span {{ current.action }}
            el-form-item(label="Страница")
              span {{ current.page }}
            el-form-item(label="IP")
              span {{ current.ip }}
            el-form-item(label="ID пользователя")
              span {{ current.user_id }}
            el-form-item(label="Имя пользователя")
              span {{ current.user_name }}
            el-form-item(label="Модель")
              span {{ current.model }}
            el-form-item(label="ID модели")
              span {{ current.model_id }}
            el-form-item(label="Сообщение")
              span {{ current.message }}
            el-form-item(label="Массив данных")
              span
                pre.logs-list__json {{ JSON.stringify(current.data, null, 4) }}
          .clear-left
        // div(style="text-align:right;")
          el-button(type="danger" @click="dialogPermissionVisible=false") Отменить

          el-button(type="primary" @click="confirmPermission") Сохранить

    //- el-dialog(:title="'Создать пользователя'" :visible.sync="dialogFormVisible")
      .form-container(v-loading="userCreating")
        el-form(ref="userForm" :rules="rules" :model="newUser" label-position="left" label-width="150px" style="max-width: 500px;")
          el-form-item(:label="$t('user.role')" prop="role")
            el-select(v-model="newUser.role" class="filter-item" placeholder="Please select role")
              el-option(v-for="item in nonAdminRoles" :key="item" :label="item | uppercaseFirst" :value="item")

          el-form-item(:label="$t('user.name')" prop="name")
            el-input(v-model="newUser.name")

          el-form-item(:label="$t('user.email')" prop="email")
            el-input(v-model="newUser.email")

          el-form-item(:label="$t('user.password')" prop="password")
            el-input(v-model="newUser.password" show-password)

          el-form-item(:label="$t('user.confirmPassword')" prop="confirmPassword")
            el-input(v-model="newUser.confirmPassword" show-password)


        .dialog-footer(slot="footer")
          el-button(@click="dialogFormVisible = false") Отменить

          el-button(type="primary" @click="createUser()") Сохранить

</template>
<script>
import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
import request from '@/utils/api'

export default {
  name: 'LogList',
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      loading: true,
      query: {
        page: 1,
        limit: 50,
        status: '',
        action: '',
        pageName: '',
        message: '',
        userId: '',
        userName: '',
        userEmail: '',
        date: ''
      },
      dialogViewVisible: false,
      dialogViewLoading: false,
      currentId: 0,
      current: {
        id: 'Загрузка'
      },
    }
  },
  computed: {
    isTech() {
      return this.$route.meta.is_tech
    },
    urlBase() {
      return this.isTech ? 'tech-logs' : 'logs'
    }
  },
  created() {
    this.getList()
    // if (checkPermission(['manage permission'])) {
    //   this.getPermissions();
    // }
  },
  methods: {
    openViewModal(id) {
      this.currentId = id
      this.dialogViewVisible = true
      this.fetchCurrent()
    },
    async fetchCurrent() {
      try {
        this.dialogViewLoading = true
        const response = await request({
          url: `${this.urlBase}/${this.currentId}`,
          method: 'get'
        })
        this.dialogViewLoading = false
        this.current = response.data
      } catch (error) {
        this.dialogViewLoading = false
        console.log(error)
        this.$notify({
          title: 'Ошибка загрузки!',
          message: error.message,
          type: 'success',
          duration: 2000,
        })
      }
    },

    async getList() {
      const { limit, page } = this.query
      this.loading = true
      try {
        const response = await request({
          url: `${this.urlBase}`,
          method: 'get',
          params: this.query
        })
        const { data, meta } = response
        this.list = data
        this.total = meta.total
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Ошибка загрузки!',
          message: error.message,
          type: 'success',
          duration: 2000,
        })
      }
    },
    handleFilter() {
      this.query.page = 1
      this.getList()
    }
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/blocks/users-list.sass"
.logs-list
  &__json
    font-size: 12px
    line-height: 16px
    white-space: pre-wrap
    word-wrap: break-word
.filter-container
  &__row-title
    margin-right: 20px
  &__row
    display: flex
    flex-flow: row nowrap
    align-items: center
    margin-bottom: 10px
    .filter-item
      margin-bottom: 0
</style>
